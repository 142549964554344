const redirectStop = {
	id: 'redirectStop',
	selectionType: 'single', // [single|multiple|general]
	label: 'actions.subscriptionstop.redirectStop',
	functionality: 'READ_SUBSCRIPTION', // set the functionality if needed
	checkAvailability: function () {
		return true;
	},
	runAction: function (action, model, registries) {
		// Sample code to open a model dialog
		const row = registries[0];
		// Get PK for the header
		/*const objectPk = {};
		for (var index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				objectPk[column.name] = registries[0][column.name];
			}
		}
		row.headerPk = objectPk;*/
		let stop = btoa(`{ "id": ${row.stop} }`);

		this.$router.push('/stop/read/' + stop);
		row.isAction = true;
	}
};

export default {
	gridactions: [redirectStop],
	formactions: []
};
